<div *ngIf="showReadings" class="gas-monitor-readings flex1 flexColumn jcFS aiC gap20">
    <div class="gas-monitor-readings-title flex1 jcFS aiC gap10 w100p">
        <app-icon class="gas-monitor-readings-title-icon" [faIcon]="gasReadingsIcon"></app-icon>
        <span class="gas-monitor-readings-title-title fg1">{{ 'person-detail.gas-readings.title' | translate }}</span>
        <span class="gas-monitor-readings-title-time">{{ readingTime }}</span>
    </div>
    <div class="gas-monitor-readings-list flex1 jcFS aiC gap5 w100p">
        <div *ngFor="let reading of latestGasMonitorBadge.readings" class="gas-monitor-readings-list-item flex1 flexColumn jcFS aiC gap10">
            <div class="gas-monitor-readings-list-item-code flex1 jcC aiC" [ngClass]="[ SensorStatusToColor[reading.status] ]">{{ reading.code }}</div>
            <div class="gas-monitor-readings-list-item-reading flex1 jcFS aiC">
                <span class="gas-monitor-readings-list-item-reading-value">{{ reading.value }}</span>
                <span class="gas-monitor-readings-list-item-reading-unit">{{ reading.unit }}</span>
            </div>
        </div>
    </div>
</div>