
/* Components */
import { DvrControlsComponent } from './dvr-controls/dvr-controls.component';
import { GeofenceDetailComponent } from './simple-map/detail-views/geofence-detail/geofence-detail.component';
import { ItemDetailComponent } from './simple-map/detail-views/item-detail/item-detail.component';
import { PersonDetailComponent } from './simple-map/detail-views/person-detail/person-detail.component';
import { WaltDetailComponent } from './simple-map/detail-views/walt-detail/walt-detail.component';
import { SimpleMapSearchComponent } from './simple-map/simple-map-search/simple-map-search.component';
import { SimpleMapComponent } from './simple-map/simple-map.component';
import { EventLogComponent } from './weavix-map/event-log/event-log.component';
import { MapControlComponent } from './weavix-map/map-control/map-control.component';
import { FilterRowDisplayComponent } from './weavix-map/weavix-map-filter/filter-row-display/filter-row-display.component';
import { ItemRowDisplayComponent } from './weavix-map/weavix-map-filter/item-row-display/item-row-display.component';
import { PersonRowDisplayComponent } from './weavix-map/weavix-map-filter/person-row-display/person-row-display.component';
import { WeavixMapFilterComponent } from './weavix-map/weavix-map-filter/weavix-map-filter.component';
import { LocationDetailsComponent } from './simple-map/detail-views/location-details/location-details.component';
import { GasMonitorReadingsComponent } from './simple-map/detail-views/gas-monitor-readings/gas-monitor-readings.component';
import { NetworkCoverageDetailComponent } from './simple-map/detail-views/network-coverage/network-coverage-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ComponentsModule } from 'components/components.module';


@NgModule({
    declarations: [
        DvrControlsComponent,
        EventLogComponent,
        FilterRowDisplayComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        MapControlComponent,
        PersonDetailComponent,
        PersonRowDisplayComponent,
        SimpleMapComponent,
        WeavixMapFilterComponent,
        SimpleMapSearchComponent,
        GeofenceDetailComponent,
        WaltDetailComponent,
        LocationDetailsComponent,
        GasMonitorReadingsComponent,
        NetworkCoverageDetailComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatSliderModule,
        MatTooltipModule,
        ComponentsModule,
    ],
    exports: [
        DvrControlsComponent,
        EventLogComponent,
        FilterRowDisplayComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        MapControlComponent,
        PersonDetailComponent,
        PersonRowDisplayComponent,
        SimpleMapComponent,
        SimpleMapSearchComponent,
        WeavixMapFilterComponent,
    ],
})
export class ConsoleMapModule { }
